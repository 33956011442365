export default [
      {
        path: '/auditory',
        name: 'auditory',
        component: () => import('@/views/auditory/Assignment.vue'),
        meta: {
          navActiveLink: 'auditory',
          resource: 'Second',
          action: 'read',
          pageTitle: 'Auditoria',
          breadcrumb: [
            {
              text: 'Auditoria',
              active: true,
            },
          ],
        },
      },
      {
        path: '/auditory/types-of-credit/:id',
        name: 'auditory-types-of-credit',
        component: () => import('@/views/auditory/TypesOfCredit.vue'),
        meta: {
          navActiveLink: 'auditory',
          resource: 'Ver roles',
          props: true,
          action: 'read',
          pageTitle: 'Tipos de acreencia',
          breadcrumb: [
            {
              text: 'Tipos de acreencia',
              active: true,
            },
          ],
        },
      },
      {
        path: '/auditory/credit/:typeCredit/:assignmentBatchId',
        name: 'auditory-credit',
        component: () => import('@/views/auditory/Credit.vue'),
        meta: {
          navActiveLink: 'auditory',
          resource: 'Ver roles',
          props: true,
          action: 'read',
          pageTitle: 'Acreencia',
          breadcrumb: [
            {
              text: 'Acreencia',
              active: true,
            },
          ],
        },
      },
      {
        path: '/auditory/credits/:creditId/:assignmentBatchId',
        name: 'auditory-credits',
        component: () => import('@/views/auditory/Credits.vue'),
        meta: {
          navActiveLink: 'auditory',
          resource: 'Ver roles',
          props: true,
          action: 'read',
          pageTitle: 'Creditos',
          breadcrumb: [
            {
              text: 'Creditos',
              active: true,
            },
          ],
        }
      },
      {
        path: '/auditory/glosa/:id',
        name: 'auditory-glosa',
        component: () => import('@/views/auditory/Glosa.vue'),
        meta: {
          navActiveLink: 'auditory',
          resource: 'Ver roles',
          props: true,
          action: 'read',
          pageTitle: 'Glosas',
          breadcrumb: [
            {
              text: 'Glosas',
              active: true,
            },
          ],
        },
      },
    ]
    