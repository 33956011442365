export default [
      {
        path: '/recobros/:id_recovery',
        name: 'recobros',
        component: () => import('@/views/recobros/index.vue'),
        meta: {
          resource: 'Recobros',
          action: 'read',
          pageTitle: 'Recobros'
        },
      }
    ]
    