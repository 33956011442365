import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  //baseURL: 'https://ecoopsos-backend.test/api/',
  baseURL: 'https://api.ecoopsosliquidacion.com.co/api/',
  timeout: 100000000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
