export default [
  {
    path: '/quality/productivity/batches',
    name: 'productivity-batches',
    component: () => import('@/views/quality/productivity/Batch.vue'),
    meta: {
      navActiveLink: 'quality/productivity/batches',
      resource: 'Second',
      action: 'read',
      pageTitle: 'Lotes',
      breadcrumb: [
        {
          text: 'Productividad',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quality/productivity/batch/:id',
    name: 'productivity-batch-typecredit',
    component: () => import('@/views/quality/productivity/TypesCredit.vue'),
    meta: {
      navActiveLink: 'productivity-batches',
      resource: 'Second',
      action: 'read',
      pageTitle: 'Tipos de acreencia',
      breadcrumb: [
        {
          text: 'Detalle de lote',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quality/productivity/batch/:assignmentBatchId/type-credit/:reclamationTypeId',
    name: 'productivity-batch-credits',
    component: () => import('@/views/quality/productivity/Credit.vue'),
    meta: {
      navActiveLink: 'productivity-batches',
      resource: 'Second',
      action: 'read',
      pageTitle: 'Calidad',
      breadcrumb: [
        {
          text: 'Detalle de lote',
          active: true,
        },
      ],
    },
  },
]
