export default [
      {
        path: '/radicados',
        name: 'radicados',
        component: () => import('@/views/radicados/index.vue'),
        meta: {
          resource: 'Radicados',
          action: 'read',
          pageTitle: 'Radicados'
        },
      }
    ]
    