export default [
  {
    path: '/coordination/assignment',
    name: 'assignment-coordination',
    component: () => import('@/views/coordination/assignment/Assignment.vue'),
    meta: {
      navActiveLink: 'assignment-coordination',
      resource: 'Second',
      action: 'read',
      pageTitle: 'Asignación',
      breadcrumb: [
        {
          text: 'Coordinación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/coordination/assignment/type-credits/:id',
    name: 'assignment-coordination-type-credits',
    component: () => import('@/views/coordination/assignment/TypesCredit.vue'),
    meta: {
      navActiveLink: 'assignment-coordination',
      resource: 'Second',
      action: 'read',
      pageTitle: 'Asignación',
      breadcrumb: [
        {
          text: 'Coordinación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/coordination/assignment/type-credits/:id',
    name: 'assignment-coordination-type-credits',
    component: () => import('@/views/coordination/assignment/TypesCredit.vue'),
    meta: {
      navActiveLink: 'assignment-coordination',
      resource: 'Second',
      action: 'read',
      pageTitle: 'Asignación',
      breadcrumb: [
        {
          text: 'Coordinación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/coordination/assignment-batch/:assignmentBatchId/reclamation-type/:reclamationTypeId',
    name: 'assignment-coordination-type-credits',
    component: () => import('@/views/coordination/assignment/Credits.vue'),
    meta: {
      navActiveLink: 'assignment-coordination',
      resource: 'Second',
      action: 'read',
      pageTitle: 'Asignación',
      breadcrumb: [
        {
          text: 'Coordinación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/coordination/assignment/type-credits-all',
    name: 'assignment-coordination-type-credits-all',
    component: () => import('@/views/coordination/assignment/TypesCreditAll.vue'),
    meta: {
      navActiveLink: 'assignment-coordination',
      resource: 'Second',
      action: 'read',
      pageTitle: 'Asignación',
      breadcrumb: [
        {
          text: 'Coordinación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/coordination/assignment/credits-detail-all/:reclamationTypeId',
    name: 'assignment-coordination-credits-detail-all',
    component: () => import('@/views/coordination/assignment/CreditsAll.vue'),
    meta: {
      navActiveLink: 'assignment-coordination',
      resource: 'Second',
      action: 'read',
      pageTitle: 'Asignación',
      breadcrumb: [
        {
          text: 'Coordinación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/coordination/massive-calification',
    name: 'massive-calification',
    component: () => import('@/views/coordination/massive-calification/Index.vue'),
    meta: {
      navActiveLink: 'massive-calification',
      resource: 'Second',
      action: 'read',
      pageTitle: 'Glosa masiva',
      breadcrumb: [
        {
          text: 'Glosa masiva',
          active: true,
        },
      ],
    },
  },
]
